<template lang="html">
    <div id="results-page">

      <section class="breadcrumb-section">
        <div class="container oversized-container px-lg-0">
          <span>Promociones</span>
        </div>
      </section>

      <section class="container oversized-container products-section">
        <div class="row mb-3">
          <div class="col-12 col-s-title px-lg-0">
            <h2 class="title">Promociones</h2>
          </div>
        </div>

        <div class="row align-items-start">

          <!-- Mobile filters -->
          <div class="col-12 col-filters-mobile">
            <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
            <a class="btn-filters" @click="showFilters = !showFilters">
              <i class="fas fa-filter"></i><span>Ver filtros</span>
            </a>
          </div>

          <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
            <h4 class="_title">
              <span>Filtros de Búsqueda</span>
              <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
            </h4>

            <b-form class="filters-container" @submit="onSubmitFilters">

              <div class="box-filters">
                <a class="btn-action"><i class="sy">-</i> Marca</a>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="filtersForm.brands"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2">
                    <b-form-checkbox :value="br.id" v-for="(br,indx) in brandsOpcs" :key="'bra'+indx">{{br.name}}</b-form-checkbox>

                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="box-filters">
                <a class="btn-action"><i class="sy">-</i> Categorías</a>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="filtersForm.categories"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2">
                    <b-form-checkbox :value="category.id" v-for="(category,catIdx) in categoriesOpcs" :key="'cat-'+catIdx">{{category.name}}</b-form-checkbox>

                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="box-filters">
                <a class="btn-action"><i class="sy">-</i> Precio</a>

                <div class="box-filters-group">
                  <b-form-select class="c-filter-select" v-model="filtersForm.price">
                    <b-form-select-option :value="null">Cualquiera</b-form-select-option>
                    <b-form-select-option :value="800">$800 o menos</b-form-select-option>
                    <b-form-select-option :value="1499">$1,499 o menos</b-form-select-option>
                    <b-form-select-option :value="999999999999999999">$1,500 o más</b-form-select-option>
                  </b-form-select>
                </div>
              </div>

            </b-form>
          </div>
          <!--  -->

          <!-- Resultados en columnas -->
          <div class="col-lg">
            <div class="row sample-products-wrapper xxl-4e" v-if="!isLoading">
              <div class="col-sm-6 col-md-6 col-xl-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
                <a class="outlined-box">
                  <router-link class="fake-link" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>
                    <div class="discount">-24%</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.brand }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <div class="b-prices">
                      <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                      <h6 class="price p-discount" v-if="product.priceOld"><span>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.priceOld) }}</span></h6>
                    </div>
                    <div class="box-shipping-type">
                      <span class="icon i-1" v-if="product.envio == 1"></span>
                      <span class="text" v-if="product.envio == 1">Llega mañana</span>
                      <span class="icon i-2" v-if="product.envio == 2"></span>
                      <span class="text" v-if="product.envio == 2">Llega en 4 días</span>
                      <span class="icon i-3" v-if="product.envio == 3"></span>
                      <span class="text" v-if="product.envio == 3">Llega en 1 semana</span>
                    </div>
                  </div>

                  <cart-component :product="product"></cart-component>
                </a>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center" v-if="!products.length && !isLoading">
                <h5>No se encontraron resultados</h5>
              </div>

              <div class="col-12" v-if="isLoading">
                <loading-component></loading-component>
              </div>
            </div>
          </div>
          <!--  -->

        </div>
      </section>

    </div>
  </template>

  <script>
  import LoadingComponent from '../shared/loading-component.vue';
  export default {
    data(){
      return{
        isLoading: true,
        showFilters: false,

        filtersForm: {
          brands:[],
          categories:[],
          price:null,
        },

        products: [
          {
            "id": 5,
            "brand": "T-FAL",
            "name": "Licuadora T-fal ln283bmx 550w gris 2vel vidrio 1.25lt",
            "price": "539",
            "priceOld": "800",
            "stock": 9,
            "envio": 1,
            "imageUrl": "public/images/pages/products/product-1.jpg"
          },
          {
            "id": 2,
            "brand": "Gibson",
            "name": "Batería gibson elite 131383.06r negro 6 piezas base induccion 2.5mm",
            "price": "850.00",
            "priceOld": "1399",
            "stock": 2,
            "envio": 1,
            "imageUrl": "public/images/pages/products/product-2.jpg"
          },
          {
            "id": 3,
            "brand": "Koblenz",
            "name": "Estufa Koblenz eke505f piso inn 30pulgadas gris cubierta inox capelo",
            "price": "9799",
            "priceOld": "10529",
            "stock": 3,
            "envio": 2,
            "imageUrl": "public/images/pages/products/product-3.jpg"
          },
          {
            "id": 4,
            "brand": "Wendy",
            "name": "Colchon Wendy burgos suave individual beige no vuelta",
            "price": "2979",
            "priceOld": "3",
            "stock": 4,
            "envio": 3,
            "imageUrl": "public/images/pages/products/product-4.jpg"
          },
          {
            "id": 5,
            "brand": "Mabe",
            "name": "Refrigerador Mabe rme360fgmrq0 14pies dark silver despachador",
            "price": "12099",
            "priceOld": "13499",
            "stock": 1,
            "envio": 1,
            "imageUrl": "public/images/pages/products/product-5.jpg"
          },
          {
            "id": 6,
            "brand": "America",
            "name": "Colchon America tiffany firme matrimonial gris/negro no vuelta colchoneta europea refuerzo perimetra",
            "price": "9109",
            "priceOld": null,
            "stock": 1,
            "envio": 2,
            "imageUrl": "public/images/pages/products/product-6.jpg"
          },
          {
            "id": 5,
            "brand": "T-FAL",
            "name": "Licuadora T-fal ln283bmx 550w gris 2vel vidrio 1.25lt",
            "price": "539",
            "priceOld": "800",
            "stock": 9,
            "envio": 3,
            "imageUrl": "public/images/pages/products/product-1.jpg"
          },
          {
            "id": 2,
            "brand": "Gibson",
            "name": "Batería gibson elite 131383.06r negro 6 piezas base induccion 2.5mm",
            "price": "850.00",
            "priceOld": "1399",
            "stock": 2,
            "envio": 3,
            "imageUrl": "public/images/pages/products/product-2.jpg"
          },
          {
            "id": 3,
            "brand": "Koblenz",
            "name": "Estufa Koblenz eke505f piso inn 30pulgadas gris cubierta inox capelo",
            "price": "9799",
            "priceOld": "10529",
            "stock": 3,
            "envio": 2,
            "imageUrl": "public/images/pages/products/product-3.jpg"
          },
          {
            "id": 4,
            "brand": "Wendy",
            "name": "Colchon Wendy burgos suave individual beige no vuelta",
            "price": "2979",
            "priceOld": "3",
            "stock": 4,
            "envio": 1,
            "imageUrl": "public/images/pages/products/product-4.jpg"
          },
          {
            "id": 5,
            "brand": "Mabe",
            "name": "Refrigerador Mabe rme360fgmrq0 14pies dark silver despachador",
            "price": "12099",
            "priceOld": "13499",
            "stock": 1,
            "envio": 1,
            "imageUrl": "public/images/pages/products/product-5.jpg"
          },
          {
            "id": 6,
            "brand": "America",
            "name": "Colchon America tiffany firme matrimonial gris/negro no vuelta colchoneta europea refuerzo perimetra",
            "price": "9109",
            "priceOld": null,
            "stock": 1,
            "envio": 1,
            "imageUrl": "public/images/pages/products/product-6.jpg"
          },
        ],

        category: [],
        category_id:null,
        brandsOpcs:[
          { id: 1, name: 'Marca 1' },
          { id: 2, name: 'Marca 2' },
          { id: 3, name: 'Marca 3' },
          { id: 4, name: 'Marca 4' },
          { id: 5, name: 'Marca 5' },
          { id: 6, name: 'Marca 6' },
        ],
        categoriesOpcs:[
          { id: 1, name: 'Categoría 1' },
          { id: 2, name: 'Categoría 2' },
          { id: 3, name: 'Categoría 3' },
          { id: 4, name: 'Categoría 4' },
        ],
      }
    },

    components: {
      LoadingComponent
    },

    watch: {
      "$route.params.id" : function(v){
        this.category_id = this.$root._getURLID(this.$route.params.id);
        if(this.category_id){
            this.products=[];
            this.getProductsCategory(this.category_id);
        }
      },
      "filtersForm.brands" : function(v){
          this.getProductsCategory(this.category_id);
      },
      "filtersForm.categories" : function(v){
          this.getProductsCategory(this.category_id);
      },
      "filtersForm.price" : function(v){
          this.getProductsCategory(this.category_id);
      },
    },

    methods: {
      onSubmitFilters(evt) {
        evt.preventDefault();
      },

      getProducts: function(){
        axios.get(tools.url('/api/products')).then((response)=>{
          this.products = response.data;
        }).catch((error)=>{
          console.log(error);
        })
      },

      getProductsCategory: function(category_id){
        this.isLoading = true;
        axios.post(tools.url('/api/products/category/'+category_id),this.filtersForm).then((response) =>{
            this.products = response.data.products;
            this.category = response.data.category;
            this.isLoading = false;
        }).catch((error)=>{
          console.log(error);
        });
      },
      getBrandsOpcs(){
        axios.get(tools.url('/api/brandsOpcs/'+this.category_id+'/s')).then((response)=>{
          this.brandsOpcs = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
      getCategoriesOpcs(){
        axios.get(tools.url('/api/categoriesOpcs/'+this.category_id+'/s')).then((response)=>{
          this.categoriesOpcs = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
    },

    mounted(){
      setTimeout( ()=>{ this.isLoading = false; }, 2000); // ejemplo de "loading" despues de cargar

      // if(this.$route.params.id){
      //    this.category_id = this.$root._getURLID(this.$route.params.id);
      //    this.getProductsCategory(this.category_id);
      //    this.getBrandsOpcs();
      //  this.getCategoriesOpcs();
      // }
    },
  }
  </script>
