<template lang="html">
  <b-form @submit="onSubmit()">
    <h6 class="f-w-600">Contraseña</h6>
    <hr class="c-hr" />

    <b-form-group class="custom-input"
      label="Escriba su actual contraseña:"
      label-for="i-password">
      <b-form-input id="i-password" type="password"
        v-model="form.password" size="sm"
        required placeholder="********" />
    </b-form-group>

    <b-form-group class="custom-input"
      label="Escriba su nueva contraseña:"
      label-for="i-npassword">
      <b-form-input id="i-npassword" type="password"
        v-model="form.npassword" size="sm"
        required placeholder="********" />
    </b-form-group>

    <b-form-group class="custom-input"
      label="Vuelva a escribir su nueva contraseña:"
      label-for="i-conpassword">
      <b-form-input id="i-conpassword" type="password"
        v-model="form.conpassword" size="sm"
        required placeholder="********" />
    </b-form-group>

    <b-button type="submit" class="btn-red btn-form" variant="primary">Cambiar contraseña</b-button>

  </b-form>
</template>

<script>
export default {
  data(){
    return{
      form: {
        password: '',
        npassword: '',
        conpassword: ''
      }
    }
  },

  methods: {
    onSubmit(){
      console.log('Mi contraseña...');
    }
  }
}
</script>
