<template lang="html">
  <div class="sweet-modal-icon sweet-modal-success animate">
    <span class="sweet-modal-line sweet-modal-tip animateSuccessTip"></span>
    <span class="sweet-modal-line sweet-modal-long animateSuccessLong"></span>
    <div class="sweet-modal-placeholder"></div>
    <div class="sweet-modal-fix"></div>
  </div>
</template>

<script>
export default {
}
</script>
