/*
 *
 * Estos componentes estan presentes solo en el website
 *
 */

const page="./page/";
// import slick from 'vue-slick';
import BootstrapVue from 'bootstrap-vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import SweetModal from 'sweet-modal-vue/src/plugin.js';
import VueSocialSharing from 'vue-social-sharing'; // https://www.npmjs.com/package/vue-social-sharing

function plugin (Vue) {
	//Componentes llamados
  Vue.component('my-header', require(page+'shared/header.vue').default);
  Vue.component('my-footer', require(page+'shared/footer.vue').default);
  Vue.component('cart-component', require(page+'products/cart-component.vue').default);
  Vue.component('success-component-v2', require(page+'shared/success-component-v2.vue').default);
	// Vue.component('slick',slick);
  Vue.use(BootstrapVue);
  Vue.use(VueAwesomeSwiper, /* { default options with global component } */);
  Vue.use(SweetModal);
  Vue.use(VueSocialSharing);
}

// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
